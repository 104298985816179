import type { IPublicData, IWixStyleParams } from '@wix/tpa-settings';
import type { IControllerConfig } from '@wix/yoshi-flow-editor';
import settingsParams from '../../../components/CategoryMenu/settingsParams';

const migrateStylesBooleansToSettingsConfig = [
  settingsParams.showAllPostsCategory.key,
  settingsParams.showPostCount.key,
  {
    param: `${settingsParams.showAllPostsCategory.key}Mobile`,
    mobileParam: `${settingsParams.showAllPostsCategory.key}▶︎m`,
    dependency: 'category-menu-mobile-isDisplaySettingsEnabled',
  },
  {
    param: `${settingsParams.showPostCount.key}Mobile`,
    mobileParam: `${settingsParams.showPostCount.key}▶︎m`,
    dependency: 'category-menu-mobile-isDisplaySettingsEnabled',
  },
];

export const migrateStyleParamsToSettings = (
  publicData: IPublicData,
  styleParams: IWixStyleParams,
): IControllerConfig['publicData'] => {
  const { booleans } = styleParams;

  const component = publicData.COMPONENT || {};

  for (const key of migrateStylesBooleansToSettingsConfig) {
    if (typeof key === 'string' && typeof booleans[key] === 'boolean') {
      component[key] = booleans[key];
    }

    if (typeof key === 'object' && booleans[key.dependency] === true) {
      component[key.mobileParam] = booleans[key.param];
    }
  }

  return {
    APP: { ...publicData.APP },
    COMPONENT: component,
  };
};
